import React, { useState } from "react";
import Layout from "../components/Layout";
import HeaderPhoto from "../components/HeaderPhoto";
import photo from "../images/kalk.webp";
import * as styles from "../styles/kalkulator.module.css";
import { Button, TextField } from "@mui/material";

export default function Kalkulator() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [bmi, setBmi] = useState();
  const [info, setInfo] = useState();

  const handleClick = () => {
    const val = (
      [Number(weight) / Number(height) / Number(height)] * 10000
    ).toFixed(1);
    setBmi(val);
    if (val < 18.5) {
      setInfo("Neuhranjenost");
    } else if (val > 18.5 && val <= 24.9) {
      setInfo("Normalna telesna masa");
    } else if (val > 24.9 && val < 30) {
      setInfo("Prekomerna telesna masa");
    } else {
      setInfo("Gojaznost");
    }
  };

  return (
    <Layout>
      <HeaderPhoto title="BMI Kalkulator" photo={photo} />
      <div className={styles.calcWrap}>
        <div className={styles.calc}>
          <div className={styles.calcTitle}>Izračunajte svoj BMI</div>
          <TextField
            classes={{ root: styles.input }}
            label="Visina"
            variant="outlined"
            size="small"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          <br />
          <TextField
            classes={{ root: styles.input }}
            label="Težina"
            variant="outlined"
            size="small"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          <br />
          <Button variant="contained" onClick={handleClick}>
            Izračunaj
          </Button>
        </div>
        {Boolean(bmi) && (
          <div className={styles.result}>
            <div>{bmi}</div>
            <div className={styles.info}>{info}</div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
